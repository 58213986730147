import React, { ComponentProps, useEffect, useState } from "react";
import ModuleLayout from "../../components/Layouts";
import { AiApi } from "../../api";
import FormTextToImage from "./FormTextToImage";
import "./index.scss";
import Prediction from "./Prediction";

const ModAi: React.FC = () => {
  const [states, setStates] = useState({
    data: { url: "" },
    loading: true,
    loadingPicture: false,
    predictions: [] as Awaited<ReturnType<(typeof AiApi)["predictions"]>>,
    modelOptions: [] as ComponentProps<typeof FormTextToImage>["modelOptions"],
    advancedSettings: {} as ComponentProps<typeof FormTextToImage>["advancedSettings"],
    modelDefaultValue: undefined,
  });

  const fetchData = async () => {
    setStates((s) => ({ ...s, loading: true }));
    const predictions = (await AiApi.predictions()) as any;
    const { models, advancedSettings } = await AiApi.models();

    const modelOptions = models.map((model: any) => ({
      label: model.label === "Flux Dev" ? "Charging Station" : model.label,
      value: model.value,
      disabled: !model.active,
    }));

    const modelDefaultValue = modelOptions.length > 0 ? modelOptions[1].value : undefined;

    setStates((s) => ({ ...s, predictions, modelOptions, modelDefaultValue, advancedSettings, loading: false }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (states.loading || !states.data) return <div>Loading...</div>;

  return (
    <ModuleLayout
      wrapperProps={{ className: "mod-ai" }}
      sidebarContent={
        <FormTextToImage
          modelOptions={states.modelOptions}
          modelDefaultValue={states.modelDefaultValue}
          advancedSettings={states.advancedSettings}
          onSubmit={() => setStates((s) => ({ ...s, loadingPicture: true }))}
          onSubmitSucess={(url: string) => setStates((s) => ({ ...s, data: { url }, loadingPicture: false }))}
        />
      }
      modalContent={<></>}
      modalOpen={false}
      closeModal={() => {}}
    >
      <div className="mod-ai__container">
        {states.loadingPicture && <div className="text-white-70">Loading...</div>}
        {!states.loadingPicture && states.data.url && <img src={states.data.url} alt="AI" />}

        <div className="predictions-container">
          {states.predictions.map((prediction) => (
            <Prediction {...prediction} />
          ))}
        </div>
      </div>
    </ModuleLayout>
  );
};

export default ModAi;
