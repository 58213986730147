import React, { useState } from "react";
import "./index.scss";
import { P, P2 } from "@ci/Fonts";
import CallToAction from "@ci/Buttons/CallToAction";

type Props = {
  currentYear: number;
  lastYearDays: number;
  currentYearDays: number;
  currentYearUsedDays: number;
  currentYearBridgeDays: number;
  currentYearPlannedDays: number;
  currentYearRemainingDays: number;
  onClickBtn: () => void;
};

const SVGArrowUp = () => (
  <svg className="arrow-up" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M14 11.5L9 6.5L4 11.5" stroke="#EEEEEE" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const SVGCalendar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
    <path
      d="M1 5H13M1 5V11.6001C1 12.4402 1 12.86 1.16349 13.1809C1.3073 13.4632 1.5366 13.6929 1.81885 13.8367C2.1394 14 2.55925 14 3.39768 14H10.6023C11.4408 14 11.86 14 12.1805 13.8367C12.4628 13.6929 12.6929 13.4632 12.8367 13.1809C13 12.8604 13 12.4411 13 11.6027V5M1 5V4.40015C1 3.56007 1 3.13972 1.16349 2.81885C1.3073 2.5366 1.5366 2.3073 1.81885 2.16349C2.13972 2 2.56007 2 3.40015 2H4M13 5V4.39768C13 3.55925 13 3.1394 12.8367 2.81885C12.6929 2.5366 12.4628 2.3073 12.1805 2.16349C11.8597 2 11.4402 2 10.6001 2H10M10 0.5V2M10 2H4M4 0.5V2"
      stroke="#EEEEEE"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const CardVacationsInfo: React.FC<Props> = ({
  currentYear,
  lastYearDays,
  currentYearBridgeDays,
  currentYearDays,
  currentYearPlannedDays,
  currentYearRemainingDays,
  currentYearUsedDays,
  onClickBtn,
}) => {
  const [open, setOpen] = useState(true);

  return (
    <div className="card-vacationns-info">
      <div className={`details-header ${open ? "open" : ""}`} onClick={() => setOpen(!open)}>
        <div className="details-header_left">
          <SVGCalendar />
          <P className="text-white-100">Urlaubstage {currentYear}</P>
        </div>
        <SVGArrowUp />
      </div>
      <div className={`details-wrapper ${open ? "open" : ""}`}>
        <div className="details">
          <P>Übertrag vom Vorjahr</P>
          <P>{lastYearDays} Tage</P>
        </div>
        <div className="details">
          <P>Anspruch</P>
          <P>{currentYearDays} Tage</P>
        </div>
        <div className="details">
          <P>Genommen</P>
          <P>{currentYearUsedDays} Tage</P>
        </div>
        <div className="details">
          <P>Brückentage</P>
          <P>{currentYearBridgeDays} Tage</P>
        </div>
        <div className="details">
          <P2>Geplant</P2>
          <P2>{currentYearPlannedDays} Tage</P2>
        </div>
      </div>
      <div className="details-sumary">
        <P className="text-white-100">Verfügbar {currentYear}</P>
        <P className="text-white-100">{currentYearRemainingDays} Tage</P>
      </div>
      <CallToAction className="mt-8 text-center cursor-pointer" onClick={onClickBtn}>
        <P2 className="" tag="span">
          Abwesenheit beantragen
        </P2>
      </CallToAction>
    </div>
  );
};

export default CardVacationsInfo;
