import React, { useEffect } from "react";
import "./index.scss";
import { H2 } from "@ci/Fonts";
import Swal from "sweetalert2";
import { LoginAPI } from "../../api";

//http://localhost:3001/verify?token=23d7425c-4da4-4502-b0e2-fc2900675618

const MagicLink: React.FC = () => {
  const [data, setData] = React.useState({ email: "", token: "" });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const email = urlParams.get("email");
    if (token && email) setData({ email, token });
  }, []);

  useEffect(() => {
    if (data.token && data.email) {
      _verifyLink();
    }
  }, [data]);

  const _invalidLink = () => {
    Swal.fire({
      title: "Invalid Link.",
      text: "Something went wrong, please try again requesting other email.",
      icon: "error",
    });
  };

  const _verifyLink = async () => {
    const callbackSucess = (sessionToken: string) => {
      localStorage.setItem("sessionToken", sessionToken);
      setTimeout(() => {
        window.location.href = "/ai";
      }, 2000);
    };
    LoginAPI.verifyMagicLink(data.token, data.email, callbackSucess, _invalidLink);
  };

  return (
    <div className="magic-link">
      <div className="magic-link-container">
        <H2 className="text-center mb-10 magic-link__text">Verifying your link...</H2>
      </div>
    </div>
  );
};

export default MagicLink;
