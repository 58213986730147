import { api, CONNECTED } from "./config";
import * as FIXTURES from "./Fixures";
import * as Types from "./types";

const AiEndpoints = {
  get: {
    predictions: `/models/predictions`,
    models: `/models`,
  },
  post: {
    predict: `/models/predict`,
    favorite: `/models/predictions/favorite`,
  },
};

export const AiApi = {
  predict: async ({
    prompt,
    model,
    advancedSettings,
  }: {
    prompt: string;
    model: string;
    advancedSettings: { [key: string]: string | number | boolean };
  }) => {
    const mockPrompt =
      "A modern electric vehicle (EV) is parked in front of a BMW iX model charging station, set against a sunny backdrop of a large parking lot with palm trees lining the background. The charging station has a metallic, industrial look with glowing green accents, featuring two cables neatly hanging from it. In the distance, mountains rise under a clear blue sky. The setting is lively, with other cars parked nearby and capturing the essence of a vibrant yet eco-friendly environment";

    const modifiedPrompt = modifyPrompt(mockPrompt, advancedSettings.background as any, advancedSettings.time as any);
    console.log(modifiedPrompt);

    if (!CONNECTED) {
      return FIXTURES.aiFixture.precition[0].url;
    }

    const response = (await api.post(AiEndpoints.post.predict, {
      prompt: modifiedPrompt + " Important to consider: " + prompt,
      model,
    })) as Types.AiApiPredictResponse;

    return response.data.urls[0].url;
  },
  predictions: async () => {
    if (!CONNECTED) {
      return FIXTURES.aiFixture.predictions;
    }

    const response = (await api.get(AiEndpoints.get.predictions)) as Types.AiApiPredictionsResponse;
    return response.data.predictions.filter((prediction) => prediction.images.length > 0);
  },
  models: async () => {
    // if (!CONNECTED) {
    //   await setTimeout(() => {}, 500);
    //   return FIXTURES.aiFixture.models;
    // }

    const response = (await api.get(AiEndpoints.get.models)) as Types.AiApiModelsResponse;
    return { models: response.data.models, advancedSettings: FIXTURES.aiFixture.advancedSettings };
  },
  favorite: async ({ predictionId, imageUrl, value }: Types.AiApiFavoriteRequest) => {
    const response = (await api.post(AiEndpoints.post.favorite, {
      predictionId,
      imageUrl,
      value,
    })) as Types.AiApiPredictResponse;

    return;
  },
};

const timeDescription = {
  day: "under a bright, clear blue sky",
  afternoon: "with the sun casting warm golden light",
  night: "illuminated by the glow of streetlights and stars",
  morning: "with soft sunlight and a fresh atmosphere",
};

const modifyPrompt = (prompt: string, background: string, time: keyof typeof timeDescription): string => {
  const backgroundDescription: Record<string, string> = {
    countryside: "set in a serene countryside with rolling green hills",
    urban: "set in a bustling urban environment with tall skyscrapers",
    beach: "near a sunny beach with waves gently lapping the shore",
    forest: "surrounded by a lush forest with towering trees",
    mountains: "with majestic mountains rising in the distance",
    desert: "in a vast desert with golden sands stretching endlessly",
    underwater: "submerged underwater, with colorful marine life",
    space: "floating in the vastness of space, with stars and planets",
  };

  const newBackground = backgroundDescription[background] || "in a generic setting";
  const newTime = timeDescription[time] || "at an unspecified time";

  return prompt
    .replace(/set against a sunny backdrop of [^,]+/, `${newBackground}`)
    .replace(/under a clear blue sky/, `${newTime}`);
};
