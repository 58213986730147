const PREDICTIONS = [
  {
    id: "16",
    prompt: "A gray car driving in the desert.",
    model: "flux-dev",
    images: [
      {
        url: "https://www.oktopods.de/_next/image?url=%2Fimages%2Fservices%2Fgen-ai%2Fhero-desktop.jpg&w=2048&q=95",
        suggestedPrompt: "",
      },
    ],
    favorite: true,
    status: "success",
  },

  {
    id: "15",
    prompt: "Traffic on the antartic highway.",
    model: "flux-schnell",

    images: [
      {
        url: "https://www.oktopods.de/_next/image?url=%2Fimages%2Fservices%2Fgen-ai%2Four-process-01.png&w=1920&q=95",
        suggestedPrompt: "",
      },
    ],
    favorite: true,
    status: "success",
  },

  {
    id: "14",
    prompt:
      "Red car following a fas driver in the desert. Red car following a fas driver in the desert. Red car following a fas driver in the desert. Red car following a fas driver in the desert. Red car following a fas driver in the desert. Red car following a fas driver in the desert. Red car following a fas driver in the desert.",
    model: "flux-schnell",
    images: [
      {
        url: "https://www.oktopods.de/images/services/gen-ai/streamlined-production.jpg",
        suggestedPrompt: "",
      },
    ],
    favorite: true,
    status: "success",
  },
  {
    id: "161",
    prompt: "A gray car driving in the desert.",
    model: "flux-dev",
    images: [
      {
        url: "https://www.oktopods.de/_next/image?url=%2Fimages%2Fservices%2Fgen-ai%2Fhero-desktop.jpg&w=2048&q=95",
        suggestedPrompt: "",
      },
    ],

    favorite: true,
    status: "success",
  },
  {
    id: "151",
    prompt: "Traffic on the antartic highway.",
    model: "flux-schnell",

    images: [
      {
        url: "https://www.oktopods.de/_next/image?url=%2Fimages%2Fservices%2Fgen-ai%2Four-process-01.png&w=1920&q=95",
        suggestedPrompt: "",
      },
    ],
    favorite: true,
    status: "success",
  },
];

const MODELS = [
  {
    value: "flux-schnell",
    label: "Flux Schnell",
    active: true,
  },
  {
    value: "flux-dev",
    label: "Flux Dev",
    active: true,
  },
  {
    value: "ideogram",
    label: "Ideogram",
    active: true,
  },
  {
    value: "recraft-v3",
    label: "Recraft",
    active: true,
  },
  {
    value: "flux-dev-ninja-vacuum",
    label: "Flux Dev Ninja Vacuum",
    active: true,
  },
  {
    value: "dall-e",
    label: "Dall-e",
    active: false,
  },
];

const ADVANCED_SETTINGS = [
  {
    type: "select" as const,
    name: "background",
    label: "Background",
    options: [
      { label: "Countryside", value: "countryside" },
      { label: "Urban", value: "urban" },
      { label: "Beach", value: "beach" },
      { label: "Forest", value: "forest" },
      { label: "Mountains", value: "mountains" },
      { label: "Desert", value: "desert" },
      { label: "Underwater", value: "underwater" },
      { label: "Space", value: "space" },
    ],
  },
  {
    type: "select" as const,
    name: "time",
    label: "Time",
    options: [
      { label: "Day", value: "day" },
      { label: "Afternoon", value: "afternoon" },
      { label: "Night", value: "night" },
      { label: "Morning", value: "morning" },
    ],
  },
  {
    type: "checkboxArray" as const,
    name: "details",
    label: "Details",
    options: [
      { label: "Person", value: "person" },
      { label: "Car", value: "car" },
    ],
  },
];

const PREDITION = [
  {
    url: "https://www.oktopods.de/_next/image?url=%2Fimages%2Fservices%2Fgen-ai%2Fhero-desktop.jpg&w=2048&q=95",
    suggestedPrompt: "",
  },
];

const FIXTURES = {
  predictions: PREDICTIONS,
  models: MODELS,
  precition: PREDITION,
  advancedSettings: ADVANCED_SETTINGS,
};

export default FIXTURES;
