import React from "react";
import { ReactComponent as SVGClose } from "assets/icons/close.svg";
import "./index.scss";

interface SidebarRightProps {
  isVisible: boolean;
  onClose: () => void;
  content: React.ReactNode;
}

const SidebarRight: React.FC<SidebarRightProps> = ({
  isVisible,
  onClose,
  content,
}) => {
  const [closing, setClosing] = React.useState(false);
  if (!isVisible) return null;

  const _onClose = () => {
    setClosing(true);
    setTimeout(() => {
      onClose();
      setClosing(false);
    }, 300); // 300 is the time defined in the css Keyframe animation.
  };

  return (
    <div className={`sidebar-right ${closing ? "closing" : ""}`}>
      <SVGClose className="close-icon" onClick={_onClose} />
      {content}
    </div>
  );
};

export default SidebarRight;
