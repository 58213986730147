import Checkbox from "@ci/Inputs/Checkbox";
import CheckboxArray from "@ci/Inputs/CheckboxArray";
import Dropdown from "@ci/Inputs/Dropdown";
import { ComponentProps, useCallback, useEffect, useState } from "react";

export type AdvancedOption = {
  type: "select" | "checkboxArray";
  name: string;
  label: string;
  options?: {
    label: string;
    value: string;
  }[];
};

type Props = {
  onChange: (advancedOptions: { [k: string]: string }) => void;
  advancedOptions: AdvancedOption[];
};

const AdvancedOptions: React.FC<Props> = ({ onChange, advancedOptions }) => {
  const [state, setState] = useState<{ [k: string]: string }>({});

  useEffect(() => {
    if (!onChange) return;
    // construct resulting dictionary/map of choices
    const res: { [k: string]: string } = {};
    advancedOptions.forEach((option) => {
      if (state[option.name] !== undefined && state[option.name] !== "") res[option.name] = state[option.name];
    });

    console.log("res", res);
    if (Object.keys(res).length > 0) onChange(res);
  }, [state]);

  const _onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const _onSelectionChange = useCallback(
    ({ name, selected }: { name: string; selected: string[] }) => {
      setState((state) => ({ ...state, [name]: selected.join(",") }));
    },
    [setState]
  );

  return advancedOptions.map(({ type, ...option }) => {
    switch (type) {
      case "select": {
        const { options, ...rest } = option;
        if (!options || options.length === 0) return null;
        return <Dropdown key={option.name} options={options} onChange={_onChange} {...rest} />;
      }
      case "checkboxArray": {
        const { options, ...rest } = option;
        if (!options || options.length === 0) return null;
        return <CheckboxArray key={option.name} onSelectionChange={_onSelectionChange} options={options} {...rest} />;
      }
      default:
        return null;
    }
  });
};

export default AdvancedOptions;
