import CallToAction from "./CallToAction";
import Delete from "./Delete";
import Primary from "./Primary";
import Secondary from "./Secondary";

export { CallToAction, Primary, Secondary, Delete };

export default {
  CallToAction,
  Primary,
  Secondary,
  Delete,
};
