import React, { ComponentProps, useId } from "react";
import Form from "react-bootstrap/Form";
import HelptextPopover from "./HelptextPopover";

type Props = {
  label?: string;
  helptext?: string;
  wrapperProps?: React.ComponentProps<typeof Form.Group>;
  labelProps?: React.ComponentProps<typeof Form.Label>;
  helptextProps?: React.ComponentProps<typeof HelptextPopover>["helptextProps"];
} & React.ComponentProps<typeof Form.Control>;

function InputWithoutRef(
  props: Props & {
    inputRef: React.Ref<HTMLInputElement>;
  }
) {
  const {
    type,
    label,
    helptext,
    wrapperProps,
    labelProps,
    helptextProps,
    inputRef,
    ...inputProps
  } = props;

  const id = useId();

  const inputType = (type ?? "text") as ComponentProps<"input">["type"];

  return (
    <Form.Group {...wrapperProps}>
      {typeof label !== "undefined" && (
        <Form.Label htmlFor={id} {...labelProps}>
          {label}
          {typeof helptext !== "undefined" && (
            <HelptextPopover helptext={helptext} {...helptextProps} />
          )}
        </Form.Label>
      )}
      <Form.Control id={id} type={inputType} ref={inputRef} {...inputProps} />
    </Form.Group>
  );
}

const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => (
  <InputWithoutRef type={props.type} {...props} inputRef={ref} />
)) as React.ForwardRefRenderFunction<HTMLInputElement, Props>; // typecasting because forward ref Omit<> doesn't work currently

Input.displayName = "Input";

export default Input;
