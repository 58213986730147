import Popover from "../../Popover";

import styles from "./HelptextPopover.module.scss";

type Props = {
  helptext?: React.ReactNode;
  helptextProps?: React.ComponentProps<typeof Popover>;
};

export default function HelptextPopover(props: Props) {
  return (
    <Popover popoverBody={props.helptext} {...props.helptextProps}>
      <span className={styles.HelptextPopoverIcon}>Info</span>
    </Popover>
  );
}
