import React from "react";
import "./index.scss";

type Props = {
  content: React.ReactNode;
}

const SidebarLeft: React.FC<Props> = ({ content }) => {
  return <div className="sidebar-left">{content}</div>;
};

export default SidebarLeft;
