import { ComponentProps } from "react";

import buttonStyles from "./Buttons.module.scss";

type PrimaryProps = {
  tag?: "a" | "button";
  variant?: "fill" | "outline" | "text";
  size?: "large" | "medium" | "small";
} & (
  | ({
      tag?: "a";
    } & ComponentProps<"a">)
  | ({
      tag: "button";
    } & ComponentProps<"button">)
);

export default function Primary(props: PrimaryProps) {
  const {
    tag: Tag = "a",
    children,
    className = "",
    variant = "outline",
    size = "medium",
    ...restProps
  } = props;

  // Duplication until TS can grasp that the restProps are
  if (Tag === "a") {
    return (
      <Tag
        className={[
          buttonStyles.button,
          buttonStyles.primary,
          "inline-block",
          buttonStyles[variant],
          buttonStyles[size],
          className,
        ].join(" ")}
        {...(restProps as ComponentProps<"a">)}
      >
        <span>{children}</span>
      </Tag>
    );
  }
  return (
    <Tag
      className={[
        buttonStyles.button,
        buttonStyles.primary,
        buttonStyles[variant],
        buttonStyles[size],
        className,
      ].join(" ")}
      {...(restProps as ComponentProps<"button">)}
    >
      <span>{children}</span>
    </Tag>
  );
}
