import React, { ComponentProps, useEffect, useState } from "react";
import SidebarLeft from "./SidebarLeft";
import SidebarRight from "./SidebarRight";
import "./index.scss";

interface ModuleLayoutProps {
  children: React.ReactNode;
  sidebarContent?: React.ReactNode;
  modalContent?: React.ReactNode;
  modalOpen: boolean;
  closeModal: () => void;
  wrapperProps?: ComponentProps<"div">;
}

const ModuleLayout: React.FC<ModuleLayoutProps> = ({
  children,
  sidebarContent,
  modalContent,
  modalOpen,
  closeModal,
  wrapperProps: { className: wrapperClassName, ...wrapperProps } = {},
}) => {
  const [isRightSidebarVisible, setRightSidebarVisible] = useState(modalOpen);

  useEffect(() => {
    setRightSidebarVisible(modalOpen);
  }, [modalOpen]);

  const toggleRightSidebar = () => {
    setRightSidebarVisible(!isRightSidebarVisible);
    closeModal();
  };

  return (
    <div className={["main-layout", wrapperClassName].join(" ")} {...wrapperProps}>
      <SidebarLeft content={sidebarContent} />
      <div className="content">{children}</div>
      <SidebarRight isVisible={isRightSidebarVisible} onClose={toggleRightSidebar} content={modalContent} />
    </div>
  );
};

export default ModuleLayout;
