import React from "react";
import Login from "./views/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./views/Dashboard";
import routes from "./routes";
import MagicLink from "./views/Login/MagicLink";
import ModVacation from "./views/ModVacations";
import ModAi from "./views/ModAi";

function App() {
  return (
    <Router>
      <Routes>
        <Route path={routes.login} element={<Login />} />
        <Route path={routes.magicLink} element={<MagicLink />} />
        <Route path={routes.dashboard} element={<Dashboard />} />
        <Route path={routes.modVacation} element={<ModVacation />} />
        <Route path={routes.modAi} element={<ModAi />} />
        {/* <Route path="/vacation" element={<Vacation />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
