import { ComponentProps } from "react";

import buttonStyles from "./Buttons.module.scss";

type CallToActionProps = {
  tag?: "a" | "button";
  variant?: "border-colorful" | "no-border" | "filled-colorful";
} & (
  | ({
      tag?: "a";
    } & ComponentProps<"a">)
  | ({
      tag: "button";
    } & ComponentProps<"button">)
);

export default function CallToAction(props: CallToActionProps) {
  const {
    tag: Tag = "a",
    children,
    className = "",
    variant = "border-colorful",
    ...restProps
  } = props;

  // Duplication until TS can grasp that the restProps are
  if (Tag === "a") {
    return (
      <Tag
        className={[
          buttonStyles.button,
          buttonStyles.callToAction,
          "inline-block",
          buttonStyles[variant],
          className,
        ].join(" ")}
        {...(restProps as ComponentProps<"a">)}
      >
        {children}
      </Tag>
    );
  }
  return (
    <Tag
      className={[
        buttonStyles.button,
        buttonStyles.callToAction,
        buttonStyles[variant],
        className,
      ].join(" ")}
      {...(restProps as ComponentProps<"button">)}
    >
      {children}
    </Tag>
  );
}
