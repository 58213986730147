import { api } from "./config";
import { AiApi } from "./AiApi";
import * as Types from "./types";

export { AiApi };

const LoginEndpoints = {
  get: {
    magicLink: (email: string) => `/magicLink?email=${encodeURIComponent(email)}`,
  },
  post: {
    verifyMagicLink: `/magicLink/verify`,
  },
};

const DashboardEndpoints = {
  get: {
    dashboard: `/dashboard`,
  },
};

const UserVacationEndpoints = {
  get: {
    userVacationData: `/user/vacations`,
  },
};

export const LoginAPI = {
  magicLink: async (email: string) => {
    const response: Types.MagicLinkResponse = await api.get(LoginEndpoints.get.magicLink(email));
    return response;
  },
  verifyMagicLink: async (
    token: string,
    email: string,
    calbackSuccess: (sessionToken: string) => void,
    fallback: () => void
  ) => {
    const response: Types.MagicLinkVerifyResponse = await api.post(LoginEndpoints.post.verifyMagicLink, {
      token,
      email,
    });
    if (response.status === 200) {
      calbackSuccess(response.data.sessionToken);
    } else {
      fallback();
    }
  },
};

export const DashboardApi = {
  dashboard: async () => {
    const response: Types.MagicLinkResponse = await api.get(DashboardEndpoints.get.dashboard);
    return response;
  },
};

export const UserVacationApi = {
  userVacationData: async () => {
    const response: Types.UserVacationResponse = await api.get(UserVacationEndpoints.get.userVacationData);
    return response.data;
  },
};
