import React from "react";
import "./index.scss";
import { LoginAPI } from "../../api";
import * as Helpers from "./helpers";
import Swal from "sweetalert2";
import Buttons from "@ci/Buttons";
import { H5 } from "@ci/Fonts";
import Input from "@ci/Inputs/Input";
import Form from "@ci/Form/Form";

const Login: React.FC = () => {
  const [email, setEmail] = React.useState<string>("");

  const _onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(ev.target.value);
  };

  const _onLogin = async () => {
    if (!Helpers.isValidEmail(email)) {
      Swal.fire({
        title: "invalid email!",
        text: "It seems you've made a typo.",
        icon: "error",
      });
      return;
    }

    const response = await LoginAPI.magicLink(email);

    if (response.status === 200) {
      Swal.fire({
        title: "Check your inbox!",
        text: "You should've got the link to log in.",
        icon: "success",
      });
    } else {
      Swal.fire({
        title: "Ops sorry!",
        text: response.data.message,
        icon: "error",
      });
    }
  };

  return (
    <div className="login">
      <Form as="div" className="login-container">
        <H5 className="text-center mb-2 mt-3">Anmelden</H5>
        <Input placeholder="E-Mail" onChange={_onChange} />
        <Buttons.Primary variant="outline" className="mt-2" onClick={_onLogin}>
          Anmelden
        </Buttons.Primary>
      </Form>
    </div>
  );
};

export default Login;
