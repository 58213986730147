import { PropsWithChildren, useId } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ReactPopover from "react-bootstrap/Popover";

import styles from "./Popover.module.scss";

type PopoverProps = {
  popoverHeader?: React.ReactNode;
  popoverBody?: React.ReactNode;
  children?: React.ReactNode;
};

export default function Popover(props: PropsWithChildren<PopoverProps>) {
  const id = useId();

  const popover = (
    <ReactPopover id={id}>
      {props.popoverHeader && (
        <ReactPopover.Header>{props.popoverHeader}</ReactPopover.Header>
      )}
      <ReactPopover.Body>{props.popoverBody}</ReactPopover.Body>
    </ReactPopover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="auto"
      overlay={popover}
      rootClose
    >
      <button type="button" className={styles.PopoverToggle}>
        {props.children}
      </button>
    </OverlayTrigger>
  );
}
