import DATA from "./data";

const parseDate = (dateStr: string): Date => {
  const [day, month, year] = dateStr.split(".").map(Number);
  return new Date(year, month - 1, day);
};

const validateDates = (startDateStr: string, endDateStr: string): boolean => {
  const startDate = parseDate(startDateStr);
  const endDate = parseDate(endDateStr);

  return startDate <= endDate;
};

const calculateDaysToBeTaken = (vom: string, bis: string, companyData: typeof DATA.companyData) => {
  const weekdays = getWeekdaysBetweenDates(vom, bis);

  const publicHolidaysInRange = companyData.publicHolidays2024.reduce((acc, holiday) => {
    if (isDateInRange(holiday.date, vom, bis)) {
      acc++;
    }
    return acc;
  }, 0);

  const bridgeDays = companyData.bridgesDays2024.reduce((acc, holiday) => {
    if (isDateInRange(holiday.date, vom, bis)) {
      acc++;
    }
    return acc;
  }, 0);

  console.log(`Number of weekdays: ${weekdays}`);
  console.log(`Number of publicHolidaysInRange: ${publicHolidaysInRange}`);
  console.log(`Number of bridgeDays: ${bridgeDays}`);
  console.log(`Number of days to be taken: ${weekdays - publicHolidaysInRange - bridgeDays}`);

  return weekdays - publicHolidaysInRange - bridgeDays;
};

function getWeekdaysBetweenDates(startDateStr: string, endDateStr: string): number {
  const startDate = parseDate(startDateStr);
  const endDate = parseDate(endDateStr);

  // If startDate is after endDate, swap them
  if (startDate > endDate) [startDate as Date, endDate as Date] = [endDate, startDate];

  let count = 0;
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      // Monday to Friday
      count++;
    }
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  return count;
}

const isDateInRange = (targetDateStr: string, startDateStr: string, endDateStr: string): boolean => {
  const targetDate = parseDate(targetDateStr);
  const startDate = parseDate(startDateStr);
  const endDate = parseDate(endDateStr);

  // Ensure startDate is before or equal to endDate
  if (startDate > endDate) [startDate as Date, endDate as Date] = [endDate, startDate];

  // Check if targetDate is between startDate and endDate (inclusive)
  return targetDate >= startDate && targetDate <= endDate;
};

const getPlannedVacationDays = (vacations: typeof DATA.userData.vacations) => {
  return vacations.filter((e) => e.status === "pending").reduce((acc, curr) => acc + curr.daysTaken, 0);
};

const getApprovedVacationDays = (vacations: typeof DATA.userData.vacations) => {
  return vacations.filter((e) => e.status === "approved").reduce((acc, curr) => acc + curr.daysTaken, 0);
};

const currentYearDays = (contract: typeof DATA.userData.contract, vacations: typeof DATA.userData.vacations) => {
  // const lastYearDays = USER_DATA.remainingLastYearVacationDays;
  const currentYearDays = contract.numVacationDays;
  const usedDays = getApprovedVacationDays(vacations);
  const plannedDays = getPlannedVacationDays(vacations);
  return currentYearDays - usedDays - plannedDays;
};

export {
  getWeekdaysBetweenDates,
  validateDates,
  calculateDaysToBeTaken,
  getPlannedVacationDays,
  getApprovedVacationDays,
  currentYearDays,
};
