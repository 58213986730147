import { AiApi } from "api";
import "./index.scss";
import Favicon from "components/Icons/Favicon";
import { ReactComponent as SVGDownload } from "@/src/assets/icons/download.svg";
import { useState } from "react";

type TpImage = {
  url: string;
  suggestedPrompt: string;
};

type Props = {
  id: string;
  prompt: string;
  model: string;
  images: TpImage[];
  status: string;
  favorite: boolean;
};

const Prediction: React.FC<Props> = ({ id, images, prompt, favorite }) => {
  const [states, setStates] = useState({ favorite: favorite });
  const onCheckFavorite = async () => {
    AiApi.favorite({ predictionId: id, imageUrl: images[0].url, value: !states.favorite });
    setStates((s) => ({ ...s, favorite: !s.favorite }));
  };

  return (
    <article className="prediction-item mb-4" key={id}>
      {images.map((image, i) => (
        <a key={i} href={image.url} target="_blank" rel="noreferrer">
          <img className="prediction-img" key={image.url} src={image.url} alt="AI" />
        </a>
      ))}
      <div className="prediction-footer">
        <p className="prompt-text-multiline">
          <b>Prompt: </b>
          {prompt}
        </p>

        <div className="prediction-controls">
          <button
            type="button"
            className="empty-button"
            title={states.favorite ? "Remove from favorites" : "Add to favorites"}
            onClick={() => onCheckFavorite()}
          >
            <Favicon active={states.favorite} />
          </button>

          {images.map((image, i) => (
            <a key={i} href={image.url} rel="noreferrer" download title="download">
              <SVGDownload />
            </a>
          ))}
        </div>
      </div>
    </article>
  );
};

export default Prediction;
