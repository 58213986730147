import React, { ComponentProps, useId } from "react";
import Form from "react-bootstrap/Form";
import HelptextPopover from "./HelptextPopover";

type Props = {
  label: string;
  placeholder?: string;
  defaultValue?: string;
  options: { value: string; label: string }[];
  optionProps?: ComponentProps<"option">;
  wrapperProps?: ComponentProps<typeof Form.Group>;
  labelProps?: ComponentProps<typeof Form.Label>;
  helptext?: string;
  helptextProps?: React.ComponentProps<typeof HelptextPopover>["helptextProps"];
  errortext?: string;
  errortextProps?: React.ComponentProps<typeof Form.Control.Feedback>;
} & ComponentProps<typeof Form.Select>;

function DropdownWithoutRef(
  props: Props & {
    inputRef: React.Ref<HTMLSelectElement>;
  }
) {
  const {
    label,
    optionProps,
    options,
    placeholder,
    inputRef,
    labelProps,
    wrapperProps,
    helptext,
    helptextProps,
    errortext,
    errortextProps,
    ...selectProps
  } = props;

  const id = useId();

  selectProps["aria-label"] = selectProps["aria-label"] ?? props.label;

  return (
    <Form.Group {...wrapperProps}>
      {typeof label !== "undefined" && (
        <Form.Label htmlFor={id} {...labelProps}>
          {label}
          {typeof helptext !== "undefined" && (
            <HelptextPopover helptext={helptext} {...helptextProps} />
          )}
        </Form.Label>
      )}
      <Form.Select id={id} ref={inputRef} {...selectProps}>
        {typeof placeholder !== "undefined" && (
          <option value="" {...optionProps}>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
      {typeof errortext !== "undefined" && (
        <Form.Control.Feedback type="invalid" {...errortextProps}>
          {errortext}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

const Dropdown = React.forwardRef<HTMLSelectElement, Props>((props, ref) => (
  <DropdownWithoutRef
    label={props.label}
    options={props.options}
    inputRef={ref}
    {...props}
  />
)) as React.ForwardRefRenderFunction<HTMLSelectElement, Props>; // typecasting because forward ref Omit<> doesn't work currently

Dropdown.displayName = "Dropdown";

export default Dropdown;
