import { ComponentProps } from "react";
import Card from "react-bootstrap/Card";

import "./form.scss";

type Props = { bodyProps?: ComponentProps<typeof Card.Body> } & ComponentProps<
  typeof Card
>;

export default function Form(props: Props) {
  const { as: _as, children, className, bodyProps, ...rest } = props;

  const { className: bodyClassName, ...restBodyProps } = bodyProps ?? {};

  return (
    <Card as={_as || "form"} {...rest} className={`${className ?? "w-100"}`}>
      <Card.Body
        className={`${bodyClassName ?? "form-vertical"}`}
        {...restBodyProps}
      >
        {children}
      </Card.Body>
    </Card>
  );
}
