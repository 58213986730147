/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useId } from "react";
import Form from "react-bootstrap/Form";
import HelptextPopover from "./HelptextPopover";

type Props = {
  label?: string;
  labelProps?: React.ComponentProps<typeof Form.Label>;
  variant?: "large" | "medium" | "small";
  labelPosition?: "top" | "bottom" | "left" | "right";
  name: string;
  helptext?: string;
  helptextProps?: React.ComponentProps<typeof HelptextPopover>["helptextProps"];
  errortext?: string;
  errortextProps?: React.ComponentProps<typeof Form.Control.Feedback>;
  options: { value: string; label: string; checked?: boolean }[];
  wrapperProps?: React.ComponentProps<typeof Form.Group>;
  onSelectionChange?: (props: { name: string; selected: string[] }) => void;
} & React.ComponentProps<typeof Form.Check>;

function CheckboxArrayWithoutRef(
  props: Props & {
    inputRef: React.Ref<HTMLInputElement>;
  }
) {
  const {
    label,
    name,
    wrapperProps,
    options,
    type,
    helptext,
    helptextProps,
    inputRef,
    onSelectionChange,
    labelProps,
    onChange,
    variant = "medium",
    labelPosition = "right",
    checked,
    errortext,
    errortextProps,
    ...inputProps
  } = props;

  const id = useId();

  const [selected, setSelected] = React.useState<string[]>([]);

  const _onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelected((prev) => {
        if (e.target.checked) {
          return [...prev, e.target.value];
        } else {
          return prev.filter((value) => value !== e.target.value);
        }
      });
      if (onChange) {
        onChange(e);
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (typeof onSelectionChange === "function") {
      onSelectionChange({ name, selected });
    }
  }, [selected, name, onSelectionChange]);

  const optionsExist = typeof options !== "undefined" && options.length > 0;

  return (
    <Form.Group as="fieldset" {...wrapperProps}>
      {typeof label !== "undefined" && (
        <Form.Label as="legend" {...labelProps}>
          {label}
          {typeof helptext !== "undefined" && (
            <HelptextPopover helptext={helptext} {...helptextProps} />
          )}
        </Form.Label>
      )}
      {optionsExist ? (
        <div className="row">
          {options.map((option) => (
            <div key={option.value} className="col-12 col-sm-6">
              <div
                className={`form-check form-check--${variant} form--label-${labelPosition}`}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`${id}-${option.value}`}
                  ref={inputRef}
                  value={option.value}
                  onChange={_onChange}
                  name={name}
                  defaultChecked={option.checked}
                  {...inputProps}
                  {...inputProps}
                />
                <span className="form-check-icon"></span>
                <label
                  className="form-check-label"
                  htmlFor={`${id}-${option.value}`}
                >
                  {option.label}
                </label>
              </div>
            </div>
          ))}
        </div>
      ) : null}
      {typeof errortext !== "undefined" && (
        <Form.Control.Feedback type="invalid" {...errortextProps}>
          {errortext}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

const CheckboxArray = React.forwardRef<HTMLInputElement, Props>(
  ({ type, options, name, ...restProps }, ref) => (
    <CheckboxArrayWithoutRef
      type={type}
      name={name}
      options={options}
      {...restProps}
      inputRef={ref}
    />
  )
) as React.ForwardRefRenderFunction<HTMLInputElement, Props>; // typecasting because forward ref Omit<> doesn't work currently

CheckboxArray.displayName = "CheckboxArray";

export default CheckboxArray;
