import axios, { AxiosResponse } from "axios";

// const API_ENDPOINT = "http://localhost:3000";
const API_ENDPOINT = "https://z5j9zufrr5.execute-api.eu-central-1.amazonaws.com";

// https://vedczmxyyk.execute-api.us-east-1.amazonaws.com/dev/magicLink

export const CONNECTED = true;

export const api = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// Custom response interceptor
api.interceptors.response.use(
  (response: AxiosResponse) => {
    // Handle successful responses
    return response;
  },
  (error) => {
    // Handle errors
    return errorHandler(error);
  }
);

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("sessionToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const errorHandler = (error: any) => {
  console.log("errorHandler -> error", error);
  const statusCode = error.response?.status;

  if (error.code === "ERR_CANCELED") {
    return Promise.resolve();
  }

  if (statusCode === 404) {
    // console.error(error);
    return error.response;
  }

  if (statusCode === 401) {
    window.location.replace("/");
    return Promise.resolve();
  }

  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

// Registering the custom error handler
// api.interceptors.response.use(undefined, (error) => errorHandler(error));
