import React, { ChangeEventHandler, useCallback, useState, ComponentProps } from "react";
import "./index.scss";
import { AiApi } from "api";
import TextArea from "@ci/Inputs/TextArea";
import Form from "@ci/Form/Form";
import Dropdown from "@ci/Inputs/Dropdown";
import Buttons from "@ci/Buttons";
import Hr from "@ci/Hr";
import Collapsible from "@ci/Collapsible/Collapsible";
import AdvancedOptions from "./AdvancedOptions";

type Props = {
  modelOptions: {
    label: string;
    value: string;
  }[];
  modelDefaultValue?: string;
  advancedSettings: ComponentProps<typeof AdvancedOptions>["advancedOptions"];
  onSubmit: () => void;
  onSubmitSucess: (url: string) => void;
};

const FormDataInit = {
  prompt: "",
  model: "flux-dev",
  advancedSettings: {
    background: "countryside",
    time: "day",
  },
};

const FormTextToImage: React.FC<Props> = ({
  modelOptions,
  modelDefaultValue,
  onSubmit,
  onSubmitSucess,
  advancedSettings,
}) => {
  const [formData, setFormData] = useState(FormDataInit);

  console.log("formData", formData);

  const submit = async () => {
    onSubmit();
    const url = await AiApi.predict(formData);
    onSubmitSucess(url);
  };

  const _updateFromData = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const _onChangeDropdown: ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (e.target.value === "") return; // TODO: handle empty value? or unnecessary?
    setFormData({ ...formData, model: e.target.value });
  };

  const _onChangeAdvancedSettings = useCallback((advancedSettings: (typeof FormDataInit)["advancedSettings"]) => {
    setFormData((formData) => ({ ...formData, advancedSettings }));
  }, []);

  return (
    <Form as="div">
      <div className="mb-2">
        <TextArea
          name="prompt"
          rows={10}
          style={{ resize: "none" }}
          placeholder="Enter your prompt..."
          value={formData.prompt}
          onChange={_updateFromData}
        />
      </div>

      {modelOptions.length > 0 && (
        <div className="mb-2">
          <Collapsible toggleText="Model Settings" initOpened>
            <div className="d-flex flex-column gap-3 mt-2">
              <Dropdown
                name="model"
                label=""
                options={modelOptions}
                defaultValue={modelDefaultValue}
                onChange={_onChangeDropdown}
                disabled
              />
            </div>
          </Collapsible>
        </div>
      )}

      <div className="mb-2">
        <Collapsible toggleText="Advanced Settings" initOpened>
          <div className="d-flex flex-column gap-3 mt-2">
            <AdvancedOptions onChange={_onChangeAdvancedSettings as any} advancedOptions={advancedSettings} />
          </div>
        </Collapsible>
      </div>

      <Hr />

      <Buttons.Primary variant="fill" className="w-100" onClick={submit}>
        Send Prompt
      </Buttons.Primary>
    </Form>
  );
};

export default FormTextToImage;
