import React, { useEffect, useState } from "react";
import "./index.scss";
import ModuleLayout from "../../components/Layouts";
import { H2, H3 } from "@ci/Fonts";
import CardVacationsInfo from "./SummaryCard";
import RequestVacation from "./RequestVacation";
import { UserVacationApi } from "../../api";
import * as Helpers from "./helpers";

import DATA from "./data";

type StatesProps = {
  data: (BuildDataProps & typeof DATA) | undefined;
  loading: boolean;
  openRequestVacation: boolean;
};

type BuildDataProps = {
  currentYear: number;
  lastYearDays: number;
  currentYearDays: number;
  currentYearUsedDays: number;
  currentYearBridgeDays: number;
  currentYearPlannedDays: number;
  currentYearRemainingDays: number;
  replacementOptions: { key: string; text: string; value: string }[];
  typeVacationsOptions: { key: string; text: string; value: string; disabled: boolean }[];
};

const ModVacation: React.FC = () => {
  const [states, setStates] = useState<StatesProps>({ data: undefined, loading: true, openRequestVacation: false });

  const fetchData = async () => {
    // const data = await UserVacationApi.userVacationData();
    const data = DATA;

    const buildData = {
      currentYear: 2024,
      lastYearDays: data.userData.remainingLastYearVacationDays,
      currentYearDays: data.userData.contract.numVacationDays,
      currentYearUsedDays: Helpers.getApprovedVacationDays(data.userData.vacations),
      currentYearBridgeDays: data.companyData.bridgesDays2024.length,
      currentYearPlannedDays: Helpers.getPlannedVacationDays(data.userData.vacations),
      currentYearRemainingDays: Helpers.currentYearDays(data.userData.contract, data.userData.vacations),
      replacementOptions: data.companyData.companyUsers.map((e) => ({
        key: e.name,
        text: e.name,
        value: e.name,
      })),
      typeVacationsOptions: data.companyData.typeVacations.map((e, i) => ({
        key: e,
        text: e,
        value: e,
        disabled: i !== 0,
      })),
    };

    setStates((e) => ({ ...e, data: { ...buildData, ...data }, loading: false }));

    return buildData;
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (states.loading || !states.data) return <div>Loading...</div>;

  return (
    <ModuleLayout
      sidebarContent={
        <CardVacationsInfo
          onClickBtn={() => setStates((s) => ({ ...s, openRequestVacation: !s.openRequestVacation }))}
          {...states.data}
        />
      }
      modalContent={
        <RequestVacation
          daysAvailables={states.data.currentYearRemainingDays}
          replecementOptions={states.data.replacementOptions}
          typeVacationOptions={states.data.typeVacationsOptions}
        />
      }
      modalOpen={states.openRequestVacation}
      closeModal={() => setStates((s) => ({ ...s, setOpenRequestVacation: false }))}
    >
      <div className="mod-vacations__container">
        <H2>My Vacation</H2>
        <H3 className="text-white-100">Abwensenheit</H3>
        <div className="data-json flex">
          <pre
            className="text-white-70"
            style={{ fontFamily: "monospace", whiteSpace: "pre-wrap", wordWrap: "break-word" }}
          >
            {JSON.stringify(states.data.companyData, null, 2)}
          </pre>
          <pre
            className="text-white-70"
            style={{ fontFamily: "monospace", whiteSpace: "pre-wrap", wordWrap: "break-word" }}
          >
            {JSON.stringify(states.data.userData, null, 2)}
          </pre>
        </div>
      </div>
    </ModuleLayout>
  );
};

export default ModVacation;
