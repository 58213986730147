import React, { ChangeEventHandler, useEffect, useState } from "react";
import { P, P2 } from "@ci/Fonts";
import "./index.scss";
import CallToAction from "@ci/Buttons/CallToAction";
import Dropdown from "@ci/Inputs/Dropdown";
import Input from "@ci/Inputs/Input";
import DATA from "../data";

import * as Helpers from "../helpers";

type Props = {
  daysAvailables: number;
  replecementOptions: {
    key: string;
    text: string;
    value: string;
  }[];
  typeVacationOptions: {
    key: string;
    text: string;
    value: string;
  }[];
};

const MOCK_DATA = {
  vom: "20.12.2024",
  bis: "28.12.2024",
};

const FormDataInit = {
  from: MOCK_DATA.vom,
  until: MOCK_DATA.bis,
  typeVacation: "",
  replacement: "",
  daysToBeTaken: 0,
};

const RequestVacation: React.FC<Props> = ({ replecementOptions, typeVacationOptions, daysAvailables }) => {
  const [formData, setFormData] = useState(FormDataInit);

  useEffect(() => {
    Helpers.validateDates(formData.from, formData.until) &&
      setFormData({
        ...formData,
        daysToBeTaken: Helpers.calculateDaysToBeTaken(formData.from, formData.until, DATA.companyData),
      });
  }, [formData.from, formData.until]);

  const submit = () => {};

  const _updateFromData = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const _onChangeDropdown: (name: string) => ChangeEventHandler<HTMLSelectElement> = (name: string) => (e) => {
    if (e.target.value === "") return;
    const valueSelected = e.target.value;
    setFormData({ ...formData, [name]: valueSelected });
  };

  return (
    <div className="request-vacation">
      <div className="request-vacation__header text-white-70 flex-sb mb-6">
        <P className="text-white-100 font-bold">Abwesendheit</P>
      </div>
      <div className="flex-sb mb-6 gap-5">
        {/* <input type="date" name="vom" />
        <input type="date" name="bis" /> */}
        <Input name="from" label="vom" placeholder="dd.mm.yyyy" value={formData.from} onChange={_updateFromData} />
        <Input name="until" label="bis" placeholder="dd.mm.yyyy" value={formData.until} onChange={_updateFromData} />
      </div>
      <div className="flex-sb gap-5 mb-6">
        <div className="label">
          <label className="text-white-70">Dauer</label>
          <P className="text-white-100 mt-2">{formData.daysToBeTaken} Tage</P>
        </div>
        <div className="label">
          <label className="text-white-70">Verfügbar</label>
          <P className="text-white-100 mt-2">{daysAvailables} Tage</P>
        </div>
      </div>
      <div className="flex-sb mb-6">
        <Dropdown
          name="type-vacation"
          label="Urlaubsart"
          options={(typeVacationOptions ?? []).map((option) => ({ label: option.text, value: option.value }))}
          defaultValueIndex={0}
          // onChange={_onChangeDropdown("type-vacation")}
        />
      </div>
      <div className="flex-sb mb-6">
        <Dropdown
          name="replacement"
          label="Urlaubsvertretung"
          options={(replecementOptions ?? []).map((option) => ({ label: option.text, value: option.value }))}
          // onChange={_onChangeDropdown("replacement")}
        />
      </div>
      <div className="flex-sb">
        <CallToAction className="mt-8 text-center cursor-pointer" onClick={submit} variant="filled-colorful">
          <P2 className="" tag="span">
            Beantragen
          </P2>
        </CallToAction>
        <CallToAction className="mt-8 text-center cursor-pointer" onClick={() => {}} variant="no-border">
          <P2 className="" tag="span">
            Abbrechen
          </P2>
        </CallToAction>
      </div>
    </div>
  );
};

export default RequestVacation;
