import { ReactNode, useId, useState } from "react";
import Collapse from "react-bootstrap/Collapse";

import styles from "./collapsible.module.scss";

type Props = {
  toggleText: string;
  children?: ReactNode;
  initOpened?: boolean;
  testing?: boolean;
};

export default function Collapsible(props: Props) {
  const { toggleText, children, initOpened = false } = props;

  const id = useId();
  const [open, setOpen] = useState(initOpened);
  return (
    <>
      <button
        onClick={() => setOpen(!open)}
        aria-controls={id}
        aria-expanded={open}
        className={[
          styles.CollapsibleButton,
          open ? styles.CollapsibleOpen : "",
        ].join(" ")}
        type="button"
      >
        <span>{toggleText}</span>
        <span className={styles.Line}></span>
        <span className={styles.CaretDown}></span>
      </button>
      <Collapse in={open}>
        <div id={id} className={styles.CollapsibleContent}>
          {children}
        </div>
      </Collapse>
    </>
  );
}
