import React from "react";
import styles from "./Fonts.module.scss";

type Props = {
  children: React.ReactNode;
  tag?: "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span" | "div";
  className?: string;
  uppercase?: boolean;
};

type HProps = {
  variant?: "primary" | "secondary" | "tertiary";
} & Props;

type SpanProps = {
  variant: "primary" | "secondary";
} & Props;

export const H1: React.FC<HProps> = ({
  variant = "primary",
  children,
  className = "",
  tag: Tag = "h1",
  uppercase = false,
}) => {
  return (
    <Tag
      className={[
        styles.H1,
        styles[variant],
        "text-white-70",
        uppercase ? styles["uppercase"] : "",
        className,
      ].join(" ")}
    >
      {children}
    </Tag>
  );
};

export const H2: React.FC<HProps> = ({
  variant = "primary",
  children,
  className = "",
  tag: Tag = "h2",
  uppercase = false,
}) => {
  return (
    <Tag
      className={[
        styles.H2,
        styles[variant],
        "text-white-70",
        uppercase ? styles["uppercase"] : "",
        className,
      ].join(" ")}
    >
      {children}
    </Tag>
  );
};

export const H3: React.FC<HProps> = ({
  variant = "primary",
  children,
  className = "",
  tag: Tag = "h3",
  uppercase = false,
}) => {
  return (
    <Tag
      className={[
        styles.H3,
        styles[variant],
        "text-white-70",
        uppercase ? styles["uppercase"] : "",
        className,
      ].join(" ")}
    >
      {children}
    </Tag>
  );
};

export const H4: React.FC<HProps> = ({
  variant = "primary",
  children,
  className = "",
  tag: Tag = "h3",
  uppercase = false,
}) => {
  return (
    <Tag
      className={[
        styles.H4,
        styles[variant],
        "text-white-70",
        uppercase ? styles["uppercase"] : "",
        className,
      ].join(" ")}
    >
      {children}
    </Tag>
  );
};

export const H5: React.FC<HProps> = ({
  variant = "primary",
  children,
  className = "",
  tag: Tag = "h5",
  uppercase = false,
}) => {
  return (
    <Tag
      className={[
        styles.H5,
        styles[variant],
        "text-white-70",
        uppercase ? styles["uppercase"] : "",
        className,
      ].join(" ")}
    >
      {children}
    </Tag>
  );
};

export const P: React.FC<HProps> = ({
  variant = "primary",
  children,
  className = "",
  tag: Tag = "p",
  uppercase = false,
}) => {
  return (
    <Tag
      className={[
        styles.p,
        styles[variant],
        "text-white-70",
        uppercase ? styles["uppercase"] : "",
        className,
      ].join(" ")}
    >
      {children}
    </Tag>
  );
};

export const P2: React.FC<Props> = ({
  children,
  className = "",
  tag: Tag = "p",
  uppercase = false,
}) => {
  return (
    <Tag
      className={[
        styles.p2,
        styles["primary"],
        "text-white-70",
        uppercase ? styles["uppercase"] : "",
        className,
      ].join(" ")}
    >
      {children}
    </Tag>
  );
};

export const Span: React.FC<SpanProps> = ({
  variant,
  children,
  className = "",
  tag: Tag = "span",
  uppercase = false,
}) => {
  return (
    <Tag
      className={[
        styles.span,
        styles[variant],
        "text-white-70",
        uppercase ? styles["uppercase"] : "",
        className,
      ].join(" ")}
    >
      {children}
    </Tag>
  );
};

export const Caption: React.FC<Props> = ({
  children,
  className = "",
  tag: Tag = "p",
  uppercase = false,
}) => {
  return (
    <Tag
      className={[
        styles.span,
        styles["primary"],
        "text-white-40",
        uppercase ? styles["uppercase"] : "",
        className,
      ].join(" ")}
    >
      {children}
    </Tag>
  );
};
