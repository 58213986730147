import React, { useEffect } from "react";
import "./index.scss";
import Input from "@ci/Inputs/Input";
import { DashboardApi } from "../../api";
import routes from "../../routes";
import * as Icons from "./Icons";
import { Link } from "react-router-dom";

const DATA = {
  icons: [
    { name: "My Vacation", icon: Icons.Vacation, path: routes.modVacation },
    // { name: "My Vacation Admin", icon: Icons.Vacation, path: "#" },
    { name: "AI", icon: Icons.Vacation, path: routes.modAi },
    { name: "Money", icon: Icons.Money, path: "#" },
    { name: "Team", icon: Icons.Team, path: "#" },
    { name: "Reef Rules", icon: Icons.ReefRules, path: "#" },
    { name: "Mission", icon: Icons.Mission, path: "#" },
    { name: "Sickness", icon: Icons.Sickness, path: "#" },
    { name: "Vacation", icon: Icons.Vacation, path: "#" },
    { name: "Work Time", icon: Icons.WorkTime, path: "#" },
    { name: "Workflows", icon: Icons.WorkFlows, path: "#" },
  ],
};

const Card: React.FC<{ name: string; icon: string }> = ({ name, icon }) => {
  return (
    <div className="dashboard__card rounded-lg shadow-lg p-4 h-fit justify-between">
      <img src={icon} alt="Description of SVG" className="w-20 h-20 mb-5 mt-5" />
      <h2 className="text-lg font-semibold">{name}</h2>
    </div>
  );
};

const Dashboard: React.FC = () => {
  const [data, setData] = React.useState({ email: "", loading: true });

  useEffect(() => {
    // DashboardApi.dashboard().then((response) => {
    //   response && setData({ email: response.data.message, loading: false });
    // });
  }, []);

  // if (data.loading) return null;

  return (
    <div className="dashboard">
      <div className="dashboard-container">
        <Input className="w-full mb-10" placeholder="Search" />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          {DATA.icons.map((icon, index) => (
            <Link to={icon.path} key={index}>
              <Card name={icon.name} icon={icon.icon} />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
