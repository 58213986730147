import React, { useId } from "react";
import { FormControlProps } from "react-bootstrap/esm/FormControl";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/esm/helpers";
import Form from "react-bootstrap/Form";
import HelptextPopover from "./HelptextPopover";

type Props = {
  label?: string;
  helptext?: string;
  wrapperProps?: React.ComponentProps<typeof Form.Group>;
  labelProps?: React.ComponentProps<typeof Form.Label>;
  helptextProps?: React.ComponentProps<typeof HelptextPopover>["helptextProps"];
} & React.ComponentProps<
  BsPrefixRefForwardingComponent<"textarea", FormControlProps>
>;

function TextAreaWithoutRef(
  props: Props & {
    inputRef: React.Ref<HTMLTextAreaElement>;
  }
) {
  const {
    label,
    helptext,
    wrapperProps,
    labelProps,
    helptextProps,
    inputRef,
    ...inputProps
  } = props;

  const id = useId();

  return (
    <Form.Group {...wrapperProps}>
      {typeof label !== "undefined" && (
        <Form.Label htmlFor={id} {...labelProps}>
          {label}
          {typeof helptext !== "undefined" && (
            <HelptextPopover helptext={helptext} {...helptextProps} />
          )}
        </Form.Label>
      )}
      <Form.Control id={id} ref={inputRef} as="textarea" {...inputProps} />
    </Form.Group>
  );
}

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => (
  <TextAreaWithoutRef {...props} inputRef={ref} />
)) as React.ForwardRefRenderFunction<HTMLTextAreaElement, Props>; // typecasting because forward ref Omit<> doesn't work currently

TextArea.displayName = "TextArea";

export default TextArea;
