const COMPANY_DATA = {
  publicHolidays2024: [
    {
      date: "01.01.2024",
      name: "New Year's Day",
    },
    {
      date: "29.03.2024",
      name: "Good Friday",
    },
    {
      date: "01.04.2024",
      name: "Easter Monday",
    },
    {
      date: "01.05.2024",
      name: "Labour Day",
    },
    {
      date: "09.05.2024",
      name: "Ascension Day",
    },
    {
      date: "20.05.2024",
      name: "Whit Monday",
    },
    {
      date: "03.10.2024",
      name: "German Unity Day",
    },
    {
      date: "25.12.2024",
      name: "Christmas Day",
    },
    {
      date: "26.12.2024",
      name: "Second Christmas Day",
    },
  ],
  bridgesDays2024: [
    {
      name: "Ascension Day",
      date: "10.05.2024",
    },
  ],
  blockedDays2024: [
    {
      name: "Team Event",
      date: "15.06.2024",
    },
    {
      name: "Team Event",
      date: "16.06.2024",
    },
  ],
  companyUsers: [
    {
      name: "Max Schneider",
      role: "Developer",
    },
    {
      name: "Erika Bauer",
      role: "Designer",
    },
    {
      name: "Hans Wagner",
      role: "Project Manager",
    },
    {
      name: "Lena Fischer",
      role: "Art Director",
    },
    {
      name: "Peter Müller",
      role: "Developer",
    },
    {
      name: "Petra Weber",
      role: "Designer",
    },
    {
      name: "Paul Schulz",
      role: "Project Manager",
    },
    {
      name: "Lara Becker",
      role: "Art Director",
    },
    {
      name: "Tom Hoffmann",
      role: "CGI Artist",
    },
  ],
  typeVacations: ["Bezahlter Urlaub", "Unbezahlter Urlaub", "Elternzeit", "Mutterschutz"],
};

const USER_DATA = {
  remainingLastYearVacationDays: 0,
  contract: {
    startingDate: "01.06.2024",
    numVacationDays: 30,
  },
  vacations: [
    {
      daysTaken: 4,
      startDate: "01.08.2024",
      endDate: "06.08.2024",
      status: "approved",
    },
    {
      daysTaken: 2,
      startDate: "04.09.2024",
      endDate: "06.09.2024",
      status: "approved",
    },
    {
      daysTaken: 4,
      startDate: "10.10.2024",
      endDate: "15.10.2024",
      status: "approved",
    },
    {
      daysTaken: 10,
      startDate: "10.12.2024",
      endDate: "31.12.2024",
      status: "pending",
    },
  ],
};

export default {
  userData: USER_DATA,
  companyData: COMPANY_DATA,
};
